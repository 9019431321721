
import Layout from "../../components/layout/Layout"
import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import NavigationButtons from '../../components/elements/ArrowsElements';
import { useTranslation } from 'react-i18next';

const Section = styled.div`
    margin-top: ${props => (props.isMobile ? '20vw' : '10%')};
    padding-bottom: 5%; /* Aumenta el margen inferior */
    text-align: center;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 80px;
    margin-top: ${props => (props.isMobile ? '20vw' : '10%')};
    max-width: 1200px; /* Ajusta el valor según tus necesidades */
    margin-left: auto;
    margin-right: auto;
`;


const Heading = styled.h2`
    margin-top: ${props => (props.isMobile ? '-50px' : '5px')};
    color: black;
    font-size: ${props => (props.isMobile ? '2em' : '3em')}; /* Ajusta el tamaño del título según tus necesidades */
    margin-bottom: ${props => (props.isMobile ? '-4px' : '20px')}; /* Ajusta el margen inferior según el dispositivo */
`;

const Paragraph = styled.p`
  margin-bottom: 5vw;
  text-align: justify;
`;

const FAQSectionContainer = styled.div`
    width: ${props => (props.isMobile ? '100%' : '70vw')};
    height: auto; /* Ajusta la altura automáticamente según el contenido */
    margin-top: ${props => (props.isMobile ? '10vw' : 0)};
`;





const FAQItem = ({ tema, descripcion, materiales, tiempoEstimado, isActive, handleToggle }) => {
    const { t } = useTranslation();
    return (
        <div data-wow-delay="0s" className={`wow fadeInUp flat-toggle ${isActive ? 'active' : ''}`}>
            <h6 className="toggle-title" onClick={handleToggle} style={{ margin: '10px 0', fontSize: '1.5em', maxWidth: '100%', color:'#fff' }}>
                {tema}
            </h6>
            <div className="toggle-content" style={{ display: isActive ? 'block' : 'none',color:'#fff' }}>
                <p style={{color:'#80B1B7', fontSize:'20px' }}>{descripcion}</p>
                <p style={{color:'#80B1B7', fontSize:'20px' }}>{t('materials')}: {materiales}</p>
                <p style={{color:'#80B1B7', fontSize:'20px' }}>{t('estimatedTime')}: {tiempoEstimado} {t('time')}</p>
            </div>
        </div>
    );
};



const FAQSection = ({ data, isActive, handleToggle }) => {
    
    return (
        <div className="flat-accordion">
            {data.map((item, index) => (
                <FAQItem
                    key={index}
                    tema={item[0]}
                    descripcion={item[1]}
                    materiales={item[2]}
                    tiempoEstimado={item[3]}
                    isActive={isActive.key === index}
                    handleToggle={() => handleToggle(index)}
                />
            ))}
        </div>
    );
};

const Formacion2 = () => {
    const [isActive, setIsActive] = React.useState({ key: null });
    const isMobile = useMediaQuery({ maxWidth: 768 });
    var { t } = useTranslation();
    

    const handleToggle = (index) => {
        setIsActive((prevState) => ({
            key: prevState.key === index ? null : index,
        }));
    };



    
    const CriptografiaSeguridadCourseData = [
        [
          t('introductionToCryptography'),
          t('cryptographyFundamentals'),
          t('cryptographyResources'),
          t('introductionDuration'),
        ],
        [
          t('publicKeyCryptography'),
          t('detailedPublicKeyCryptography'),
          t('publicKeyCryptographyDocuments'),
          t('publicKeyCryptographyDuration'),
        ],
        [
          t('hashingAlgorithms'),
          t('hashingDevelopment'),
          t('hashingArticles'),
          t('hashingDuration'),
        ],
        [
          t('financialTransactionsSecurity'),
          t('financialTransactionsExploration'),
          t('financialTransactionsGuides'),
          t('financialTransactionsDuration'),
        ],
        [
          t('blockchainSecurityProtocols'),
          t('securityProtocolsStudy'),
          t('blockchainSecurityProtocolsDocuments'),
          t('blockchainSecurityProtocolsDuration'),
        ],
        [
          t('securityAuditingAndTesting'),
          t('securityAuditingTechniques'),
          t('securityAuditingGuides'),
          t('securityAuditingDuration'),
        ],
        [
          t('quantumCryptography'),
          t('quantumCryptographyIntroduction'),
          t('quantumCryptographyArticles'),
          t('quantumCryptographyDuration'),
        ],
        [
          t('currentSecurityChallenges'),
          t('securityChallengesExploration'),
          t('securityChallengesAnalysis'),
          t('securityChallengesDuration'),
        ],
        [
          t('totalModuleDuration'),
          t('moduleDurationDescription'),
          t('digitalResources'),
          t('totalDuration'),
        ],
      ];
      






    return (

        <Layout headerStyle={1} footerStyle={1} pageCls="contact-us-page relative">
            <div style={{ width: '100vw', display: 'flex', flexDirection: 'column' }}>

                <Section>
                    <Container isMobile={isMobile}>
                    


                        <Heading>{t('titleFormacion2')}</Heading>
                        <NavigationButtons
                            prevTo="/formacion-1"
                            homeTo="/formacion"
                            nextTo="/formacion-3"
                        />
                        <Paragraph>
                        {t('subtitleFormacion2')}
                        </Paragraph>
                        <FAQSectionContainer isMobile={isMobile}>
                            <FAQSection data={CriptografiaSeguridadCourseData} isActive={isActive} handleToggle={handleToggle} />
                        </FAQSectionContainer>

                        <NavigationButtons
                            prevTo="/formacion-1"
                            homeTo="/formacion"
                            nextTo="/formacion-3"
                        />
                        
                    </Container>
                </Section>

              



            </div>

        </Layout>

    );
};

export default Formacion2;