import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import VideoComponent from '../QUIZ/VideoComponent';
import RoutesConfig, { routes } from './RouteComponent';

const TextComponent = ({ data }) => {
    const [sections, setSections] = useState([]);
    const [hasQuiz, setHasQuiz] = useState(false);
    const [selectedSubsection, setSelectedSubsection] = useState(null);
    const [isMenuVisible, setIsMenuVisible] = useState(true);
    const [tema, setTema] = useState('');

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (data && data.sections) {
            setSections(data.sections);
            if (data.sections[0] && data.sections[0].tema) {
                setTema(data.sections[0].tema);
            }
        }
        if (data && data.hasQuiz !== undefined) {
            setHasQuiz(data.hasQuiz);
        }
    }, [data]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const currentRoute = routes.find(route => route.path === location.pathname);
    const nextRoute = currentRoute?.next;
    const prevRoute = currentRoute?.prev;

    if (!sections.length) {
        return <div>No hay datos disponibles.</div>;
    }

    const renderSubtemaCard = () => {
        if (!selectedSubsection) {
            return (
                <div style={{
                    flex: 1,
                    padding: '20px',
                    textAlign: 'center',
                    color: '#0A5364',
                    backgroundImage: 'url(https://cdn.pixabay.com/photo/2018/08/08/12/38/internet-3592056_1280.jpg)', // Asegúrate de que la ruta de la imagen sea correcta
                    backgroundSize: 'cover', // Hace que la imagen cubra todo el espacio
                    backgroundPosition: 'center', // Centra la imagen
                    backgroundRepeat: 'no-repeat', // Evita que la imagen se repita
                    height: '100%', // Asegura que cubra todo el alto del componente
                    display: 'flex', // Para centrar el contenido dentro del contenedor
                    alignItems: 'center', // Centra verticalmente
                    justifyContent: 'center', // Centra horizontalmente
                    borderRadius: '10px' // Opcional: agrega bordes redondeados si lo deseas
                }}>
                    <h2 style={{
                        color: '#FFFFFF', // El texto es blanco para que contraste bien con la imagen
                        fontSize: '28px',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fondo semitransparente detrás del texto para mayor legibilidad
                        padding: '10px 20px',
                        borderRadius: '5px'
                    }}>
                        Selecciona un subtema para visualizar el contenido.
                    </h2>
                </div>
            );
        }


        const [sectionIndex, subtemaIndex] = selectedSubsection.split('-');
        const section = sections[sectionIndex];
        const subtema = section?.subtemas[subtemaIndex];

        if (!subtema) {
            return (
                <div style={{
                    flex: 1,
                    padding: '20px',
                    textAlign: 'center',
                    color: '#0A5364'
                }}>
                    No se encontró el contenido del subtema.
                </div>
            );
        }

        return (
            <div style={{
                flex: 1,
                backgroundColor: '#FFFFFF', // Carta en blanco
                padding: '20px',
                borderLeft: '1px solid #DDD',  // Línea divisoria
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                height: '100%',
                overflowY: 'auto',
                transition: 'margin-left 0.3s ease'
            }}>
                <h2 style={{ color: '#0A5364', marginBottom: '15px', fontSize: '28px' }}>{subtema.title || "Subtema"}</h2>

                {/* Renderiza los párrafos del contenido */}
                {subtema.content && subtema.content.map((paragraph, index) => (
                    <p key={index} style={{
                        textAlign: 'justify',
                        marginBottom: '25px',
                        fontSize: '20px',
                        lineHeight: '1.8',
                        color: '#0A5364',
                        backgroundColor: '#FFFFFF',
                        borderRadius: '10px',
                        padding: '15px'
                    }}>
                        {paragraph}
                    </p>
                ))}

                {/* Renderiza los elementos multimedia (videos) */}
                {subtema.media && subtema.media.length > 0 && subtema.media.map((mediaItem, mediaIndex) => (
                    <div key={mediaIndex} style={{
                        margin: '-10px 0',
                        textAlign: 'center',
                        borderRadius: '15px',
                        overflow: 'hidden',
                 
                  
                        transition: 'transform 0.3s ease-in-out',
                        cursor: 'pointer'
                    }} onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1)'} onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}>
                        {mediaItem.type === 'video' && (
                            <div style={{}}>
                            <VideoComponent videoUrl={mediaItem.url} text="" />
                            </div>
                        )}
                    </div>
                ))}

                {/* Renderiza las tablas si están disponibles */}
                {subtema.tabla && subtema.tabla.filas && subtema.tabla.filas.length > 0 && (
                    <div style={{
                        marginTop: '25px',
                        textAlign: 'center',
                        maxWidth: '95%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}>
                        <h2 style={{
                            color: '#0A5364',
                            marginBottom: '15px',
                            fontSize: '28px',
                            lineHeight: '1.5'
                        }}>
                            <strong>{subtema.tabla.tipo}</strong>
                        </h2>
                        <table style={{
                            width: '100%',
                            borderCollapse: 'collapse',
                            marginBottom: '25px',
                            backgroundColor: '#FFFFFF',
                            borderRadius: '10px',
                            padding: '15px',
                        }}>
                            <tbody>
                                {subtema.tabla.filas.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                        <td style={{
                                            border: '1px solid #DDD',
                                            padding: '12px',
                                            textAlign: 'left',
                                            backgroundColor: '#D4D4D4',
                                            color: '#0A5364',
                                            fontWeight: 'bold',
                                            fontSize: '18px',
                                        }}>
                                            {row.campo || row.tipo}
                                        </td>
                                        <td style={{
                                            border: '1px solid #DDD',
                                            padding: '12px',
                                            textAlign: 'left',
                                            backgroundColor: '#FFFFFF',
                                            color: '#0A5364',
                                            fontSize: '18px',
                                        }}>
                                            {row.valor || row.text}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}

                {/* Renderiza materiales de estudio si están disponibles */}
                {subtema.material_estudio && subtema.material_estudio.length > 0 && (
                    <div style={{
                        marginTop: '25px',
                        textAlign: 'center',
                        maxWidth: '95%',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }}>
                        <h2 style={{
                            color: '#0A5364',
                            marginBottom: '15px',
                            fontSize: '28px',
                            lineHeight: '1.5'
                        }}>
                            <strong>Material de Estudio</strong>
                        </h2>
                        {subtema.material_estudio.map((link, linkIndex) => (
                            <p key={linkIndex} style={{
                                fontSize: '22px',
                                marginBottom: '15px'
                            }}>
                                <a href={link.url} target="_blank" rel="noopener noreferrer" style={{
                                    color: '#0A5364',
                                    textDecoration: 'none',
                                    transition: 'color 0.3s ease'
                                }}
                                    onMouseEnter={(e) => e.target.style.color = '#0A5364'}
                                    onMouseLeave={(e) => e.target.style.color = '#0A5364'}>
                                    {link.text}
                                </a>
                            </p>
                        ))}
                    </div>
                )}
            </div>
        );
    };

    return (
        <>
            <div style={{
                width: '90%',
                padding: '20px',
                marginTop: '8%',
                marginLeft: 'auto',
                marginRight: 'auto',
                fontFamily: 'Georgia, Times New Roman, serif'
            }}>

                {/* Menú superior */}
                <header style={{
                    backgroundColor: '#C89D38', // Header amarillo
                    padding: '15px 20px',
                    marginBottom: '20px',
                    borderRadius: '5px',
                    boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <h1 style={{ margin: 0, color: '#FFFFFF', fontSize: '32px' }}>{tema}</h1>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {prevRoute && (
                            <button
                                onClick={() => navigate(prevRoute)}
                                style={{
                                    backgroundColor: '#0A5364',
                                    color: '#FFFFFF',
                                    border: 'none',
                                    padding: '10px 20px',
                                    cursor: 'pointer',
                                    marginRight: '10px',
                                    borderRadius: '5px',
                                    fontSize: '16px'
                                }}
                            >
                                ← Tema anterior
                            </button>
                        )}
                        {nextRoute && (
                            <button
                                onClick={() => navigate(nextRoute)}
                                style={{
                                    backgroundColor: '#0A5364',
                                    color: '#FFFFFF',
                                    border: 'none',
                                    padding: '10px 20px',
                                    cursor: 'pointer',
                                    borderRadius: '5px',
                                    fontSize: '16px'
                                }}
                            >
                                Siguiente tema →
                            </button>
                        )}
                    </div>
                </header>

                <div style={{ display: 'flex', alignItems: 'stretch', height: 'auto', minHeight: '500px' }}>
                    {/* Botón para ocultar/mostrar menú lateral */}
                    <button
                        onClick={() => setIsMenuVisible(!isMenuVisible)}
                        style={{
                            position: 'fixed',
                            left: isMenuVisible ? '250px' : '10px',
                            top: 'calc(50% - 25px)',
                            backgroundColor: '#0A5364',
                            color: '#FFFFFF',
                            border: 'none',
                            padding: '15px',
                            cursor: 'pointer',
                            borderRadius: '50%',
                            zIndex: 10,
                            width: '60px',
                            height: '60px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                            transition: 'left 0.3s ease'
                        }}
                    >
                        {isMenuVisible ? '⟨' : '⟩'}
                    </button>

                    <div style={{ display: 'flex', height: '100vh' }}>
                        {/* Menú lateral */}
                        <aside style={{
                            width: isMenuVisible ? '250px' : '0',
                            backgroundColor: '#0A5364', // Menú gris oscuro
                            color: '#FFFFFF', // Letras blancas
                            padding: isMenuVisible ? '20px' : '0',
                            boxShadow: '2px 0 5px rgba(0,0,0,0.1)',
                            fontFamily: 'Arial, sans-serif',
                            transition: 'width 0.3s ease, padding 0.3s ease',
                            overflow: 'hidden'
                        }}>
                            {isMenuVisible && (
                                <>
                                    <h3 style={{ marginBottom: '20px', color: '#FFFFFF', fontSize: '24px' }}>Subtemas</h3>
                                    <ul style={{ listStyle: 'none', padding: 0 }}>
                                        {sections.map((section, index) => (
                                            section.subtemas && section.subtemas.map((subtema, subIndex) => (
                                                <li key={`${index}-${subIndex}`} style={{ marginBottom: '10px' }}>
                                                    <a
                                                        href={`#subtema-${index}-${subIndex}`}
                                                        style={{
                                                            color: selectedSubsection === `${index}-${subIndex}` ? '#FFD700' : '#FFFFFF', // Color amarillo si está seleccionado
                                                            textDecoration: 'none',
                                                            display: 'block',
                                                            padding: '10px',
                                                            borderRadius: '5px',
                                                            backgroundColor: selectedSubsection === `${index}-${subIndex}` ? '#0A5364' : 'transparent',
                                                            transition: 'background-color 0.3s ease',
                                                            fontSize: '18px'
                                                        }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setSelectedSubsection(`${index}-${subIndex}`);
                                                        }}
                                                    >
                                                        {subtema.title}
                                                    </a>
                                                </li>
                                            ))
                                        ))}
                                    </ul>
                                </>
                            )}
                        </aside>

                        {/* Card con el video y contenido del subtema seleccionado */}
                        {renderSubtemaCard()}
                    </div>
                </div>

                {hasQuiz && (
                    <div style={{
                        marginTop: '40px',
                        backgroundColor: '#EAEAEA',
                        padding: '20px',
                        borderRadius: '15px',
                        textAlign: 'center',
                        color: '#0A5364'
                    }}>
                        <h2>Este módulo incluye un quiz</h2>
                    </div>
                )}

                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '40px' }}>
                    {prevRoute && (
                        <button onClick={() => navigate(prevRoute)} style={{
                            width: '60px',
                            height: '60px',
                            fontSize: '18px',
                            backgroundColor: '#0A5364',
                            color: '#FFFFFF',
                            border: 'none',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s ease, color 0.3s ease',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                            onMouseEnter={(e) => {
                                e.target.style.backgroundColor = '#FFD700';
                                e.target.style.color = '#0A5364';
                            }}
                            onMouseLeave={(e) => {
                                e.target.style.backgroundColor = '#0A5364';
                                e.target.style.color = '#FFFFFF';
                            }}>
                            ←
                        </button>
                    )}

                    {nextRoute && (
                        <button onClick={() => navigate(nextRoute)} style={{
                            width: '60px',
                            height: '60px',
                            fontSize: '18px',
                            backgroundColor: '#0A5364',
                            color: '#FFFFFF',
                            border: 'none',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s ease, color 0.3s ease',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                            onMouseEnter={(e) => {
                                e.target.style.backgroundColor = '#FFD700';
                                e.target.style.color = '#0A5364';
                            }}
                            onMouseLeave={(e) => {
                                e.target.style.backgroundColor = '#0A5364';
                                e.target.style.color = '#FFFFFF';
                            }}>
                            →
                        </button>
                    )}
                </div>

                <div style={{ height: '80px' }}></div>

            </div>
        </>
    );
};

export default TextComponent;
